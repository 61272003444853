import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';

function CountdownDisplay() {
    const messages = [
        "seeing eachother again. ❤️‍🔥",
        "spotting lil Kangaroos in their pouches. 🦘",
        "ending up with just 20% of the space in bed. 🥸",
        "shameless amounts of delivery food. 🗺️",
        "getting accidental analog nudes developped at the shop. 📸",
        "creating new memories. 🦄",
        "feeling Aphna's sweet embrace. 🥰",
        "more homemade ravioli dates, or maybe a Napoleon Cake? 🍰",
        "morning cuddles. 🤗",
        "exploring new places together. 👌",
        "surprise bruises from freestyle hugging. 💙",
        "the llama's services no longer being required. 👅",
        "falling asleep during carefully selected movies. 😴",
        "calling you all sorts of things under the sun, my honey sponge cake. 🧽"
    ];

// // correct one
const nextMeetingDate = new Date(Date.UTC(2023, 11, 14, 21, 40));


// // 14 oct
// const nextMeetingDate = new Date(Date.UTC(2023, 9, 14, 23 - 7, 0));
// // past
// const nextMeetingDate = new Date(Date.UTC(2022, 9, 14, 23 - 7, 0));


    function getTimeLeft(targetDate) {
        const now = new Date();
        const timeDiff = targetDate - now;

        const isPast = timeDiff <= 0;

        // If the date is in the past, return null.
        if (isPast) return null;

        const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
        return { days, hours, minutes };
    }

    function getMessage(index) {
        const timeLeft = getTimeLeft(nextMeetingDate);

        if (!timeLeft || (timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0)) {
            return "We're probably together right now, hurray!";
        }

        return `We're exactly ${timeLeft.days} days, ${timeLeft.hours} hours, and ${timeLeft.minutes} minutes away from ${messages[index]}`;
    }

    const [currentIndex, setCurrentIndex] = useState(0);
    const [message, setMessage] = useState(getMessage(currentIndex));

    useEffect(() => {
        let timeouts = [];
        for (let i = 0; i < messages.length; i++) {
            timeouts.push(setTimeout(() => {
                setCurrentIndex(i);
                setMessage(getMessage(i));
            }, i * 10000));
            if (i === messages.length - 1) {
                // Add an additional timeout to reset to the first element
                timeouts.push(setTimeout(() => {
                    setCurrentIndex(0);
                    setMessage(getMessage(0));
                }, (i + 1) * 10000));
            }
        }

        // Clean up the timeouts on unmount
        return () => {
            timeouts.forEach(clearTimeout);
        };
    }, []);

    const timeLeft = getTimeLeft(nextMeetingDate);
    const isLessThan24Hours = timeLeft && (timeLeft.days * 24 + timeLeft.hours) < 24;
    const isTimeZero = timeLeft && timeLeft.days === 0 && timeLeft.hours === 0 && timeLeft.minutes === 0;
    const isPast = !timeLeft;

    return (
        <div>
            <h3 className="countdown-display">{message}</h3>
            {(isPast || isLessThan24Hours || isTimeZero) && <Confetti />}
        </div>
    );
}

export default CountdownDisplay;
